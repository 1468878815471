<template>
    <section class="content">
        <div class="card card-primary">
            <div class="card-header">
                <h3 class="card-title">Calendar</h3>
            </div>
            <div class="card-body">
                <div class="alert alert-info">Data ada pada 01/2021</div>
                <FullCalendar :options="calendarOptions" />
            </div>
            </form>
        </div>
    </section>
    <!-- /.content -->
</template>
<script>
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'

export default {
    name: 'Calendar',
    components: {
        FullCalendar // make the <FullCalendar> tag available
    },
    data() {
        return {
            errors: [],
            calendarOptions: {
                plugins: [dayGridPlugin, interactionPlugin],
                initialView: 'dayGridMonth',
                events: [{
                        title: 'All Day Event',
                        start: '2021-01-01',
                    },
                    {
                        title: 'Long Event',
                        start: '2021-01-07',
                        end: '2021-01-10'
                    },
                    {
                        id: 999,
                        title: 'Repeating Event',
                        start: '2021-01-09T16:00:00'
                    },
                    {
                        id: 999,
                        title: 'Repeating Event',
                        start: '2021-01-16T16:00:00'
                    },
                    {
                        title: 'Conference',
                        start: '2021-01-11',
                        end: '2021-01-13'
                    },
                    {
                        title: 'Meeting',
                        start: '2021-01-12T10:30:00',
                        end: '2021-01-12T12:30:00'
                    },
                    {
                        title: 'Lunch',
                        start: '2021-01-12T12:00:00'
                    },
                    {
                        title: 'Meeting',
                        start: '2021-01-12T14:30:00'
                    },
                    {
                        title: 'Happy Hour',
                        start: '2021-01-12T17:30:00'
                    },
                    {
                        title: 'Dinner',
                        start: '2021-01-12T20:00:00'
                    },
                    {
                        title: 'Birthday Party',
                        start: '2021-01-13T07:00:00'
                    },
                    {
                        title: 'Click for Google',
                        url: 'http://google.com/',
                        start: '2021-01-28'
                    }
                ]
            }
        }
    },
    methods: {

    },
    mounted() {
        /*var containerEl = $('#calendar');
        console.log(containerEl)
        containerEl.fullCalendar({
            header: {
                left: 'prev,next today',
                center: 'title',
                right: 'month,agendaWeek,agendaDay,listWeek'
            },
            defaultDate: '2021-01-12',
            navLinks: true, // can click day/week names to navigate views
            editable: true,
            eventLimit: true, // allow "more" link when too many events
            events: [{
                    title: 'All Day Event',
                    start: '2021-01-01',
                },
                {
                    title: 'Long Event',
                    start: '2021-01-07',
                    end: '2021-01-10'
                },
                {
                    id: 999,
                    title: 'Repeating Event',
                    start: '2021-01-09T16:00:00'
                },
                {
                    id: 999,
                    title: 'Repeating Event',
                    start: '2021-01-16T16:00:00'
                },
                {
                    title: 'Conference',
                    start: '2021-01-11',
                    end: '2021-01-13'
                },
                {
                    title: 'Meeting',
                    start: '2021-01-12T10:30:00',
                    end: '2021-01-12T12:30:00'
                },
                {
                    title: 'Lunch',
                    start: '2021-01-12T12:00:00'
                },
                {
                    title: 'Meeting',
                    start: '2021-01-12T14:30:00'
                },
                {
                    title: 'Happy Hour',
                    start: '2021-01-12T17:30:00'
                },
                {
                    title: 'Dinner',
                    start: '2021-01-12T20:00:00'
                },
                {
                    title: 'Birthday Party',
                    start: '2021-01-13T07:00:00'
                },
                {
                    title: 'Click for Google',
                    url: 'http://google.com/',
                    start: '2021-01-28'
                }
            ]
        })*/
    }
}
</script>